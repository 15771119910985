import React, {ReactElement, ReactNode} from 'react';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {ReduxState} from '@vizir-banking/banking-app-core/dist/redux/types';
import {UserAttributes} from '@vizir-banking/banking-app-core/dist/entities/user/user';
import {
  User,
  PixContext,
} from '@vizir-banking/banking-app-pix-plugin/dist/contexts/pix-context';
import {
  ApplicationContext,
  ApplicationContextType,
} from '@vizir-banking/banking-app-core/dist/contexts/application-context';
import {AccountService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/account/account-service';
import {BankService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/bank/bank-service';
import {DictService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/dict/dict-service';
import {QrCodeService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/qr-code/qr-code-service';
import {StatementService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/statement/statement-service';
import {TransactionLimitsService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/transaction-limits/transaction-limits-service';
import {TransferService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/transfer/transfer-service';
import {BankingAccountService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-account-service';
import {BankingBankService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-bank-service';
import {BankingDictService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-dict-service';
import {BankingQrCodeService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-qr-code-service';
import {BankingStatementService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-statement-service';
import {BankingTransactionLimitsService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-transaction-limits-service';
import {BankingTransferService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-transfer-service';
import {BankingValidateTransactionService} from '@vizir-banking/banking-app-pix-plugin/dist/services/banking/banking-validate-transaction-service';
import {ValidateTransactionService} from '@vizir-banking/banking-app-pix-plugin/dist/services/interfaces/validate-transaction/validate-transaction-service';

type Props = ReturnType<typeof mapStateToProps> & {children?: ReactNode};

class Provider extends React.PureComponent<Props> {
  private dictService?: DictService;
  private transferService?: TransferService;
  private accountService?: AccountService;
  private statementService?: StatementService;
  private bankService?: BankService;
  private qrCodeService?: QrCodeService;
  private transactionLimitsService?: TransactionLimitsService;
  private validateTransactionService?: ValidateTransactionService;

  static contextType = ApplicationContext;
  public context!: ApplicationContextType;

  private mapUser = (): User | undefined => {
    const {user} = this.props;

    if (!user) {
      return;
    }

    let accountId = '';
    let cardId: string | undefined;

    if (user.accountIds && user.accountIds.length > 0) {
      accountId = user.accountIds[0].toString();
    }

    if (user.cards && user.cards.transactionCard) {
      cardId = user.cards.transactionCard.id.toString();
    }

    return {
      name: user.name,
      document: user.documentNumber,
      city: get(user, 'addresses[0].city', undefined),
      accountId,
      cardId,
      phone: get(user, 'phone', ''),
      email: get(user, 'email', ''),
    };
  };

  public getStatementService(): StatementService {
    if (this.statementService === undefined) {
      this.statementService = new BankingStatementService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.statementService;
  }

  public getAccountService(): AccountService {
    if (this.accountService === undefined) {
      this.accountService = new BankingAccountService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.accountService;
  }

  public getTransferService(): TransferService {
    if (this.transferService === undefined) {
      this.transferService = new BankingTransferService(
        this.context.bankingCoreHttpClient,
        this.getStatementService(),
      );
    }

    return this.transferService;
  }

  public getDictService(): DictService {
    if (this.dictService === undefined) {
      this.dictService = new BankingDictService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.dictService;
  }

  public getBankService(): BankService {
    if (this.bankService === undefined) {
      this.bankService = new BankingBankService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.bankService;
  }

  public getQrCodeService(): QrCodeService {
    if (this.qrCodeService === undefined) {
      this.qrCodeService = new BankingQrCodeService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.qrCodeService;
  }

  public getTransactionLimitsService(): TransactionLimitsService {
    if (this.transactionLimitsService === undefined) {
      this.transactionLimitsService = new BankingTransactionLimitsService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.transactionLimitsService;
  }

  public getValidateTransactionService(): ValidateTransactionService {
    if (this.validateTransactionService === undefined) {
      this.validateTransactionService = new BankingValidateTransactionService(
        this.context.bankingCoreHttpClient,
      );
    }

    return this.validateTransactionService;
  }

  public render(): ReactElement {
    return (
      <PixContext.Provider
        value={{
          dictService: this.getDictService(),
          transferService: this.getTransferService(),
          accountService: this.getAccountService(),
          statementService: this.getStatementService(),
          qrCodeService: this.getQrCodeService(),
          bankService: this.getBankService(),
          transactionLimitsService: this.getTransactionLimitsService(),
          validateTransactionService: this.getValidateTransactionService(),
          user: this.mapUser(),
          deviceKey: this.context.deviceKeyAdapter,
        }}
      >
        {this.props.children}
      </PixContext.Provider>
    );
  }
}

const mapStateToProps = (state: ReduxState): {user?: UserAttributes} => ({
  user: state.user.currentUser,
});

export const PixProvider = connect(mapStateToProps)(Provider);
