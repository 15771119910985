import React, {ReactElement} from 'react';
import {DocumentTypes} from '@vizir-banking/banking-app-core/dist/entities/user/enums/document-types';

import {ONBOARDING_SCREENS} from '~/navigation/screen-definitions';
import t from '~/i18n/translate';
import {Image} from '~/onboarding/screens/send-document/base-screens/shared.styles';
import image from '~/assets/images/photo_selfie.png';
import {
  OnboardingNavigationProps,
  withOnboardingNavigation,
} from '~/onboarding/navigation/with-onboarding-navigation';
import DocumentComponent from '~/onboarding/screens/send-document/base-screens/photo-document/photo-document';

const translate = (key: string): string => t(`onboarding.sendDocuments.${key}`);

type Props = OnboardingNavigationProps;

class PhotoSelfie extends React.PureComponent<Props> {
  tips = [
    {
      iconName: 'selfie',
      iconSize: 42,
      tip: translate('photoDocument.tips.selfie.center'),
    },
    {
      iconName: 'hat',
      iconSize: 42,
      tip: translate('photoDocument.tips.selfie.accessories'),
    },
    {
      iconName: 'glasses',
      iconSize: 42,
      tip: translate('photoDocument.tips.selfie.glasses'),
    },
    {
      iconName: 'light_fixture',
      iconSize: 44,
      tip: translate('photoDocument.tips.selfie.light'),
    },
  ];

  onConfirmPicture = (): void => {
    this.props.navigateToNextOnboardingScreen();
  };

  renderImage = (): ReactElement => {
    return <Image source={image} />;
  };

  render(): ReactElement {
    return (
      <DocumentComponent
        documentPreviewChildren={this.renderImage()}
        documentPreviewMessage={translate('photoSelfie.message')}
        documentPreviewTitle={translate('photoSelfie.title')}
        documentPreviewSubMessage={''}
        documentType={DocumentTypes.SELFIE}
        hasChangeCamera
        hasSelfieMask
        hasHints
        initWithFrontCamera
        onConfirmPicture={this.onConfirmPicture}
        tips={this.tips}
      />
    );
  }
}

export default withOnboardingNavigation(
  PhotoSelfie,
  ONBOARDING_SCREENS.photoSelfie,
);
