export const AppFonts = {
  primary: {
    weight: {
      normal: 'Roboto-Regular',
      100: 'Roboto-Thin',
      300: 'Roboto-Light',
      500: 'Roboto-Medium',
      600: 'Roboto-Medium', // Roboto doesn't have the 600 font weight
      800: 'Roboto-Bold', // Roboto doesn't have the 800 font weight
      900: 'Roboto-Black',
      bold: 'Roboto-Bold',
    },
  },
  button: {
    uppercaseTransform: true,
  },
};
