import {AppDetails} from '@vizir-banking/banking-app-core/dist/utils/app-details-type';

import appJSON from '~/app.json';

import {version} from '../../../package.json'; // eslint-disable-line no-restricted-imports

const appDetails: Readonly<AppDetails> = Object.freeze({
  buildNumber: null,
  name: appJSON.name,
  platform: 'web',
  version,
  storeUrl: null,
  deviceModel: null,
});

export default appDetails;
