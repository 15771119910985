import {createGlobalStyle} from 'styled-components';
import {useTheme} from '@vizir-banking/banking-app-core/dist/styles/style-functions';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${useTheme(
      ({fonts}) => fonts.primary.weight.normal,
    )}, 'Roboto', sans-serif;
  }

  body {
    background-color: ${useTheme(({colors}) => colors.screenBackground)};
    overflow: hidden;
  }

  html,
  body,
  #root,
  #drawer-root
  {
    width: 100%;
    height: 100%;
  }

  #drawer-root {
    position: absolute;
    max-width: 786px;
    transform: translateX(-100%);
    transition: transform 0.5s;
  }

  #drawer-root.drawer--open {
    z-index: 2;
    transform: translateX(0);
  }

  #drawer-root.drawer--open + #root{
    transform: translateX(95%);
  }

  #root {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: stretch;
    max-width: 786px;
    margin: 0 auto;
    position: relative;
    transition: transform 0.5s;
  }

  @font-face {
    font-family: Roboto-Medium;
    src: local('Roboto-Medium'),
      url('/fonts/Roboto-Medium.woff2') format('woff2'),
      url('/fonts/Roboto-Medium.woff') format('woff');
  }

  @font-face {
    font-family: Roboto-Medium;
    src: local('Roboto-MediumItalic'),
      url('/fonts/Roboto-MediumItalic.woff2') format('woff2'),
      url('/fonts/Roboto-MediumItalic.woff') format('woff');
    font-style: italic;
  }

  @font-face {
    font-family: Roboto-Regular;
    src: local('Roboto-Regular'),
      url('/fonts/Roboto-Regular.woff2') format('woff2'),
      url('/fonts/Roboto-Regular.woff') format('woff');
  }

  @font-face {
    font-family: Roboto-Regular;
    src: local('Roboto-Italic'),
      url('/fonts/Roboto-Italic.woff2') format('woff2'),
      url('/fonts/Roboto-Italic.woff') format('woff');
    font-style: italic;
  }

  @font-face {
    font-family: Roboto-Black;
    src: local('Roboto-Black'),
      url('/fonts/Roboto-Black.woff2') format('woff2'),
      url('/fonts/Roboto-Black.woff') format('woff');
  }

  @font-face {
    font-family: Roboto-Black;
    src: local('Roboto-BlackItalic'),
      url('/fonts/Roboto-BlackItalic.woff2') format('woff2'),
      url('/fonts/Roboto-BlackItalic.woff') format('woff');
    font-style: italic;
  }

  @font-face {
    font-family: Roboto-Bold;
    src: local('Roboto-Bold'),
      url('/fonts/Roboto-Bold.woff2') format('woff2'),
      url('/fonts/Roboto-Bold.woff') format('woff');
  }

  @font-face {
    font-family: Roboto-Bold;
    src: local('Roboto-BoldItalic'),
      url('/fonts/Roboto-BoldItalic.woff2') format('woff2'),
      url('/fonts/Roboto-BoldItalic.woff') format('woff');
    font-style: italic;
  }

  @font-face {
    font-family: Roboto-Thin;
    src: local('Roboto-Thin'),
      url('/fonts/Roboto-Thin.woff2') format('woff2'),
      url('/fonts/Roboto-Thin.woff') format('woff');
  }

  @font-face {
    font-family: Roboto-Thin;
    src: local('Roboto-ThinItalic'),
      url('/fonts/Roboto-ThinItalic.woff2') format('woff2'),
      url('/fonts/Roboto-ThinItalic.woff') format('woff');
    font-style: italic;
  }

  @font-face {
    font-family: Roboto-Light;
    src: local('Roboto-Light'),
      url('/fonts/Roboto-Light.woff2') format('woff2'),
      url('/fonts/Roboto-Light.woff') format('woff');
  }

  @font-face {
    font-family: Roboto-Light;
    src: local('Roboto-LightItalic'),
      url('/fonts/Roboto-LightItalic.woff2') format('woff2'),
      url('/fonts/Roboto-LightItalic.woff') format('woff');
    font-style: italic;
  }
`;
