import {TranslationsManager} from '@vizir-banking/banking-app-core/dist/hooks/translations-manager';

TranslationsManager.add('ptBr', 'onboarding', {
  WhatsYour: 'Qual é o seu ',
  email: 'e-mail',
  fullName: 'nome completo',
  datePlaceholder: 'DD/MM/AAAA',
  partnerData: {
    partnerData: 'Dados dos Sócios',
    partnerCompany: 'Sócio / Empresa Sócia',
    partnerCompanyName: 'Razão Social',
    partnerFullName: 'Nome Completo',
    partnerPhone: 'Celular',
    accountAdmin: 'Administrador da Conta',
    holdingMessage:
      'Nesse momento não trabalhamos com cadastros de holdings, mas em breve vamos conseguir cadastrar empresas como a sua para você aproveitar tudo por aqui! :)',
  },
  WhenWereYou: 'quando você',
  Born: 'nasceu',
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  cpfOrCnpjLabel: 'Insira o seu CPF ou CNPJ',
  cpfOrCnpj: 'CPF ou CNPJ',
  forgetPassword: 'Esqueci minha senha',
  passwordLabel: 'Insira sua senha',
  newToHere: 'É novo por aqui? ',
  registerNow: 'Faça seu cadastro',
  login: 'Fazer login',
  resetPassword: {
    modal: {
      title: 'Recuperação de senha',
      message:
        'Verifique sua caixa de entrada! Enviaremos um e-mail através da caixa dock@dock.tech com o título recuperação de acesso.',
    },
  },
  hello: 'Olá',
  ContinueToLogin: 'Para continuar, faça seu login',
  WithoutConnection:
    'Você está sem conexão com a internet. Por favor, tente novamente mais tarde.',
  phoneNumber: 'telefone',
  letsStart: 'Vamos começar!',
  zipcode: {
    zipcodeFormMessage: 'Agora informe o seu endereço',
    zipcode: 'CEP',
    infoText:
      'Utilizamos o seu endereço para o envio de correspondências, como o cartão físico quando solicitado.',
  },
  Continue: 'Continuar',
  whatIsThe: 'Qual é a ',
  legalNature: {
    legalNature: {
      whatIsThe: 'Qual é a ',
      lowerCaseLegalNature: 'natureza jurídica',
      placeholder: 'Selecione a natureza jurídica',
      modalTitle: 'Natureza Legal',
      modalMessage: 'Qual é a natureza legal da sua empresa?',
      info:
        'A informação sobre a Natureza Jurídica você encontra no cartão CNPJ da sua empresa.',
    },
    establishmentFormat: {
      whatIsThe: 'Qual é o ',
      lowerCaseEstablishmentFormat: 'forma de constituição',
      placeholder: 'Selecione a forma de constituição',
      modalTitle: 'Forma de constituição da Empresa',
      modalMessage: 'Qual é a forma de constituição da sua empresa?',
      info:
        'No Nome Empresarial do seu cartão CNPJ, você vai encontrar a sua forma de constituição, como SA, LTDA, EIRELI, ME..',
    },
  },
  fromCompany: 'da empresa?',
  fromYourCompany: ' da sua empresa?',
  changePasswordSuccessfull: 'Você redefiniu sua senha com sucesso!',
  nowCreateLabel: 'Agora crie uma',
  newPasswordLabel: 'nova senha:',
  password: 'senha',
  Password: 'Senha',
  fillInThe: 'Preencha o',
  number: 'número',
  andThe: 'e o',
  complement: 'complemento',
  zipCodeInputPlaceholder: 'CEP',
  addressInputPlaceholder: 'Endereço',
  numberInputPlaceholder: 'Número',
  complementInputPlaceholder: 'Complemento',
  NeighborhoodInputPlaceholder: 'Bairro',
  stateInputPlaceholder: 'UF',
  cityInputPlaceholder: 'Cidade',
  WhatIsYour: 'Qual é o',
  MothersCompleteName: 'nome completo da sua mãe',
  MotherNameInfoButton: 'Por que preciso do nome completo da sua mãe?',
  MotherNameInfoText:
    'Precisamos deste dado para seguir com os padrões exigidos pelo Banco Central',
  Accept: 'Aceitar',
  Decline: 'Não aceito',
  TakeAPhoto: 'Tirar foto',
  SendLater: 'Enviar depois',
  WeNeedSome: 'Precisamos de alguns ',
  documents: {
    documentsLabel: 'documentos',
    photoConfirmation: 'A foto ficou boa?',
    dismiss: 'Não',
    accept: 'Sim',
  },
  ToValidadeYourSignUp: ' para completar e validar seu cadastro:',
  validPartnersDocs: '- RG, CNH ou RNE de todos os sócios',
  ProofOfResidence: '- Comprovante de endereço',
  ArticlesOfAssociation:
    '- Contrato social, Requerimento de empresário ou certificado MEI.',
  SendNow: 'ENVIAR AGORA',
  JustALittleToFinishRegistration:
    'Falta pouco para você terminar o seu cadastro!',
  NowWeNeedSomeDocuments:
    'Precisamos agora de alguns documentos. Caso deseje, você pode enviar futuramente.',
  AcceptedDocuments: 'Tire uma foto do seu RG, CNH ou RNE da parte frontal',
  FrontPhoto: 'Foto frontal do documento',
  SendBackPhotoExplanation: 'Agora tire uma foto do verso do mesmo documento',
  BackPhoto: 'Foto do verso do documento',
  NowTakeOnePhotoOfAProofOfResidence: 'E uma foto do comprovante de residencia',
  TakeAnotherPicture: 'Tirar outra',
  ResidentialPhoto: 'Foto do comprovante residential',
  termsConditionsUrl:
    'https://lucree-static.s3.amazonaws.com/app/terms/pt-br.html',
  ReceiveEmailText:
    'Quero receber novidades da {{env.REACT_APP_CUSTOMER_NAME}}.',
  smsCode: {
    invalidCode: 'Código de validação inválido',
    resendSms: 'Enviar novamente',
    sendedSms: 'Sms enviado',
    smsCodeTitle:
      'Enviamos um código de validação via SMS para você. Preencha-o abaixo:',
    smsSubmissionFailed: 'Falha no envio do sms',
  },
  accountCreated: {
    title:
      'Sua conta digital {{env.REACT_APP_CUSTOMER_NAME}} foi criada com sucesso!',
    willBeValidated: 'Vamos avaliar seu cadastro e validar sua conta.',
    startUsing: 'Acesse agora com o seu CPF ou CNPJ e senha cadastrados.',
    buttonLabel: 'Fazer login',
  },
  CompanyPartnerInfoText:
    'Para seguir com aprovação do cadastro é necessário ter todos os sócios cadastrados. Sem essas informações o cadastro não será liberado.',
  DeletePartnerTitle: 'Excluir sócio',
  DeletePartnerInfo: 'Deseja excluir as informações deste sócio?',
  DeletePartner: ' EXCLUIR',
  KeepPartner: 'MANTER',
  AddAnotherPartner: 'Adicionar outro sócio',
  TermsAndConditions: 'Termos & Condições',
  birthdayLabel: 'data de nascimento',
  companyStatus: {
    title: {normal: 'Qual o ', bold: 'status atual ', normal2: 'da empresa?'},
    select: {title: 'Selecione o status', placeHolder: 'Selecione o status'},
    status: {
      active: 'Ativa',
      suspended: 'Suspensa',
      inept: 'Inapta',
      discharged: 'Baixada',
      null: 'Nula',
    },
    submit: 'Continuar',
  },
  annualRevenue: {
    title: {
      normal: 'Qual o ',
      bold: 'faturamento anual ',
      normal2: 'da sua empresa?',
    },
  },
  signUp: {
    welcomeTo: 'Bem-vindo a',
    yourDigitalAccount: 'Sua conta digital, sem burocracia.',
    createAccount: 'Crie sua conta',
    customer: 'Já sou cliente',
  },
  identity: {
    title: 'Preencha as informações abaixo:',
    issuerEntity: 'Orgão emissor',
    RG: 'RG',
    FU: 'UF',
    issuanceDate: 'Data de emissão',
    RGInfo: 'Essa informação também tem na CNH ou RNE',
    issuanceDateInfo: 'Pode ser do RG, CNH ou RNE',
  },
  birthday: {
    personFlow: {
      title: {
        normal: '{{name}}, quando você',
        bold: 'nasceu',
      },
    },
    companyFlow: {
      title: {
        normal: 'Qual é a',
        bold: 'data de fundação ',
        normal2: 'da {{name}}',
      },
    },
  },
  companyFantasyName: {
    title: {
      normal: 'Qual é a',
      bold: 'razão social da empresa',
    },
  },
  stateInscription: {
    start: 'Qual a ',
    stateInscription: 'inscrição estadual',
    end: ' da sua empresa?',
    checkboxLabel: 'Isento de inscrição estadual',
  },
  sendDocuments: {
    confirmDocument: {
      primaryButton: 'Continuar',
      transparentButton: 'Tirar outra foto',
    },
    photoDocument: {
      transparentButton: 'Enviar depois',
      navbarTitle: {
        DRIVER_LICENSE_FRONT: 'Foto frente',
        DRIVER_LICENSE_VERSE: 'Foto verso',
        IDENTITY_CARD_FRONT: 'Foto frente',
        IDENTITY_CARD_VERSE: 'Foto verso',
        RNE_FRONT: 'Foto frente',
        RNE_VERSE: 'Foto verso',
        SELFIE: 'Selfie',
        PROOF_OF_ADDRESS: 'Comprovante de Endereço',
      },
      cameraHint: {
        DRIVER_LICENSE_FRONT: {
          hint1: 'Frente',
          hint2: '(Lado que contém a foto)',
        },
        DRIVER_LICENSE_VERSE: {
          hint1: 'Verso',
          hint2: '(Lado que contém informações)',
        },
        IDENTITY_CARD_FRONT: {
          hint1: 'Frente',
          hint2: '(Lado que contém a foto)',
        },
        RNE_FRONT: {
          hint1: 'Frente',
          hint2: '(Lado que contém a foto)',
        },
        RNE_VERSE: {
          hint1: 'Verso',
          hint2: '(Lado que contém informações)',
        },
        IDENTITY_CARD_VERSE: {
          hint1: 'Verso',
          hint2: '(Lado que contém informações)',
        },
        SELFIE: {
          hint1: 'Selfie',
          hint2: 'Centralize seu rosto',
        },
      },
      tips: {
        title: 'Dicas para uma boa foto:',
        document: {
          plastic:
            'Retire o documento do plástico de proteção, tiraremos a foto frente (lado que contem a foto) e verso dele.',
          frame: 'Posicione o documento dentro da moldura da foto.',
          surface:
            'Tire a foto sobre uma superfície lisa. Evite fundos estampados.',
          lights:
            'Tire a foto em um lugar iluminado. Fique atento com reflexos.',
        },
        selfie: {
          center: 'Centralize seu rosto',
          accessories: 'Acessórios não são permitidos.',
          glasses: 'Óculos não são permitidos. ',
          light: 'Tire a foto em um lugar iluminado.',
        },
        buttonLabel: 'Ok, entendi',
      },
    },
    attachDocument: {
      title: {
        notAttached: 'Anexar Documento',
        attached: 'Documento anexado',
      },
      button: {
        notAttached: 'Anexar',
        attached: 'Continuar',
      },
      info: 'O documento deve estar no formato PDF e ter no máximo 2 mb.',
      maxFileSizeReached:
        'Arquivo com tamanho inválido. Tamanho máximo permitido de 2 MB',
    },
    photoMainDocument: {
      message: {
        normal: 'Agora vamos precisar do ',
        bold: 'seu documento.',
      },
      subMessage: 'Selecione o documento que deseja fotografar:',
      primaryButton: 'Continuar',
      list: {
        cnh: {
          title: 'CNH',
          label: 'Carteira de habilitação',
        },
        rg: {
          title: 'RG',
          label: 'Carteira de identidade',
        },
        rne: {
          title: 'RNE',
          label: 'Registro nacional de estrangeiros',
        },
      },
    },
    photoMainDocumentBack: {
      title: 'Verso do Documento',
      message: 'Agora tire uma foto do verso do mesmo documento',
      primaryButton: 'Continuar',
    },
    photoSelfie: {
      title: 'Selfie',
      message: 'Vamos tirar uma selfie?',
      primaryButton: 'Continuar',
    },
    photoResidential: {
      title: 'Comprovante de Endereço',
      message: 'E uma foto do comprovante de residencia',
      primaryButton: 'Continuar',
    },
    confirmMainDocument: {
      title: 'Foto frontal do documento',
    },
    confirmMainDocumentBack: {
      title: 'Foto do verso do documento',
    },
    confirmResidential: {
      title: 'Foto do comprovante residencial',
    },
    attachSocialContract: {
      subTitle:
        'Contrato social, requerimento de empresário ou certificado MEI',
    },
    attachContractChange: {
      subTitle: 'Alteração no contrato (caso possua)',
      transparentButton: 'Sem alteração de contrato',
    },
    documentImages: {
      tips: {
        title: 'Como tirar a foto:',
        front: 'Foto da Frente',
        back: 'Foto do Verso',
      },
    },
  },
  meiDocument: {
    label: 'Como empreendedor, informe o seu ',
  },
  meiInfo: {
    title: 'Dados Pessoais',
    almostThere: 'Estamos quase lá!',
    messageStart:
      'Para completar o cadastro de PJ, precisamos criar uma conta ',
    naturalPerson: 'Pessoa Física',
    messageEnd: ' para você e, por isso, vamos precisar de alguns dados seus.',
  },
  PEP: {
    yes: 'Sim',
    no: 'Não',
    title: {
      normal: 'Você é uma',
      bold: 'pessoa politicamente exposta',
    },
    infoText: {
      button: 'O que significa Pessoa Politicamente Exposta (PEP)?',
      info:
        'É considerada Pessoa Politicamente Exposta (PEP) aquela que desempenha ou desempenhou, nos últimos cinco anos, cargos públicos relevantes, no Brasil ou em outros países, bem como seus representantes, familiares e pessoas de seu relacionamento próximo, inclusive sócios ou pessoas que participem em fundos de investimento com um PEP.',
    },
    continue: 'CONTINUAR',
    required: 'Campo obrigatório',
  },
  profession: {
    title: 'Qual a sua profissão e renda média mensal?',
    profession: 'Profissão',
    monthlyIncome: 'Renda média mensal',
    continue: 'CONTINUAR',
    infoButton: 'Por que preciso informar profissão e minha renda mensal?',
    infoText:
      'Precisamos deste dado para seguir com os padrões exigidos pelo Banco Central',
    professionPlaceholder: 'Selecione uma profissão',
  },
  cnae: {
    tellUs: 'Conta para nós,',
    whatIs: 'qual é o',
    cnae: 'CNAE',
  },
  accessKey: {
    invalidKey: 'Chave de acesso inválida',
    callout:
      'A <0>Conta Digital {{env.REACT_APP_CUSTOMER_NAME}}</0> está em fase beta, e em breve estará disponível para todos os nossos clientes.',
    contactPartOne: 'Envie um e-mail para',
    contactPartTwo: 'para ser o primeiro a saber quando a conta estiver ativa.',
    collaboratorPartOne: 'É um colaborador?',
    collaboratorPartTwo: 'Digite sua <0>chave de acesso:</0>',
  },
  accountCreating: {
    sendingYourInformation: 'Pronto, suas informações estão sendo enviadas.',
    youWillBeRedirectedToTheConfirmationScreen:
      'Você será encaminhado para a tela de confirmação em breve.',
    doNotCloseTheApp: 'Não feche o aplicativo.',
    retry: 'Ops! Ocorreu algum problema ao tentar completar o seu cadastro',
    retryMessage:
      'Caso o problema persista entre em contato com a nossa Central de Atendimento',
    retryButton: 'Tentar novamente',
    retryDocumentUpload: 'Ops! Seus documentos não foram aprovados.',
    retryDocumentUploadMessage:
      'Você precisa tirar novas fotos para finalizar o seu cadastro. Confira as dicas com atenção.',
    retryDocumentUploadButton: 'Tirar novas fotos',
    errorDetail: 'Código do erro: {{errorCode}}',
    invalidData: 'Ops! Detectamos dados inválidos',
    invalidDataMessage:
      'Seu cadastro contém informações inválidas, por favor, revise os dados inseridos nos passos anteriores e tente novamente.',
    goBack: 'Voltar',
    goBackLogin: 'Ir para o login',
    failure:
      'Ops! Encontramos alguns erros nas informações que você preencheu:',
    redoButton: 'Refazer cadastro',
    contactMessage:
      'Se continuar com problemas, entre em contato com nossa Central de Atendimento.',
    contactPhone: '{{env.REACT_APP_CUSTOMER_CONTACT_PHONE}}',
  },
  sensor: {
    titleFaceId: 'Face ID',
    titleBiometrics: 'Biometria',
    descriptionFaceId: 'Deseja ativar o Face ID para acessar sua conta ?',
    descriptionBiometrics: 'Deseja ativar a Biometria para acessar sua conta ?',
    activeFaceId: 'Ativar Face ID',
    activeBiometrics: 'Ativar Biometria',
    authentication: 'Autenticação',
  },
  validateDocuments: {
    loading: {
      title: 'Verificando fotos',
      message: 'Estamos verificando sua selfie e documentos.',
      subMessage: 'Aguarde um momento.',
    },
    errors: {
      default: {
        title: 'Ops, temos um problema!',
        message:
          'Não conseguimos validar o seu documento e a sua selfie. Leia as dicas para tirar uma boa foto ou revise os dados do seu cadastro.',
      },
    },
    retryNewPhotos: 'Tirar novas fotos',
    reviewFromStart: 'Revisar os dados',
  },
});
